.CardMain {
  // width: 95%;
  .CardTop {
    width: 95%;
  }
  .CardGraphic {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 95%;
    margin: 15px 0;
  }
  .CardTableMain {
    width: 95%;
    // display: flex;
    // align-items: center;
    max-height: 83vh;
    height: 83vh;
    flex-shrink: 0;
    border-radius: 8px;
    background: #212c32;
    margin: 15px 0;
    .CardTopInside {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
      }
      .Title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: #fff;
        padding: 15px;
      }
      .FiltersName {
        margin: 15px 25px;
        width: 134px;
        height: 18px;
        border-radius: 15px;
        padding: 5px 10px;
        text-decoration: none;
        border: 4px solid #27536f;
        color: #27536f;
        outline: none;
        font-weight: bold;
      }
      .FiltersArea {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: #27536f;
        cursor: pointer;
        border: none;
        color: #fff;
        padding: 8px 20px;
        border-radius: 15px;
        width: 134px;
        margin: 15px 25px;
      }
    }
  }
  .Pagination {
    width: 95%;
    display: flex;
    color: #fff;
    justify-content: flex-end;
  }
}
