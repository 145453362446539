.ModalSmall {
  text-align: center;
  position: fixed;
  left: 35%;
  top: 35vh;
  background-color: #fff;
  box-shadow: 0 0 1000px 1000px rgba(#000000, 0.8);
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  z-index: 999;
  display: flex;
  color: #3487be;
  width: 420px;
  min-height: 180px;
  background: #212c32;
  padding: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
  }
  .Title {
    font-weight: bold;
    color: #bebebf;
    font-size: 14px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  .Link {
    border: 1px solid #3487be;
    border-radius: 8px;
    padding: 8px 14px;
  }
  .ButtonsArea {
    display: flex;
    justify-content: space-evenly;
    .ButtonConfirm {
      border-radius: 8px;
      background: #3487be;
      display: flex;
      width: 175px;
      height: 52px;
      padding: 7px 6px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #06080a;
      border: none;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
    }
    .ButtonConfirmActive {
      border-radius: 8px;
      background: #16d69c;
      display: flex;
      width: 175px;
      height: 52px;
      padding: 7px 6px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #06080a;
      border: none;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
    }
    .ButtonDelete {
      border-radius: 8px;
      background: #212c32;
      display: flex;
      width: 175px;
      height: 52px;
      padding: 7px 6px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fe0000;
      border: 1px solid #fe0000;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
    }
    .ButtonCancel {
      border-radius: 8px;
      background: #212c32;
      display: flex;
      width: 175px;
      height: 52px;
      padding: 7px 6px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #0091d7;
      border: 1px solid #0091d7;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
