.ModalDefault {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin: 5px 20px;
  text-align: left;
  font-size: 12px;
  border: 1px solid #3487be;
  padding: 5px;
  height: 130px;
  border-radius: 8px;
  li {
    color: #fff;
    label {
      color: #3487be;
    }
  }
  .ContentPlace{
    display: flex;
  }
}
.ButtonConfirm {
  border-radius: 8px;
  background: #3487be;
  display: flex;
  min-width: 160px;
  max-height: 45px;
  padding: 7px 6px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #06080a;
  border: none;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
}
.ButtonConfirmActive {
  border-radius: 8px;
  background: #16d69c;
  display: flex;
  min-width: 160px;
  max-height: 45px;
  padding: 7px 6px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #06080a;
  border: none;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
}
