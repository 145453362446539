.ModalDefault {
  text-align: center;
  position: fixed;
  left: 30%;
  top: 20vh;
  background-color: #fff;
  box-shadow: 0 0 1000px 1000px rgba(#000000, 0.8);
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 99;
  display: flex;
  color: #3487be;
  width: 50vw;
  max-width: 700px;
  min-height: 350px;
  background: #212c32;
  padding: 0 0 20px 0;
  @media screen and (max-width: 1200px) {
    width: 70%;
    left: 20%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
    top: 14vh;
  }
  .ModalDefaultTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(214, 214, 214, 0.35);
    padding: 5px 30px;
    .Title {
      color: #50a1d7;
      font-size: 24px;
      text-transform: uppercase;
    }
    .ButtonClose {
      background-color: #212c32;
      border: none;
      cursor: pointer;
      Img {
        width: 45px;
        height: 45px;
      }
    }
  }
  .ModalDefaultBottom {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ImageArea {
      position: relative;
      margin: 10px auto;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      .TransferArea {
        width: 40vw;
        height: 150px;
        border-radius: 4px;
        outline: 2px dashed #0091d7;
        background: #1a2328;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        justify-content: center;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        Img {
          max-width: 200px;
          max-height: 130px;
        }
        .TransferText {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .TransferTextImage {
            color: #bebebf;
            margin-left: 5px;
            font-size: 12px;
          }
        }
        .TransferTextFormat {
          color: #676767;
          font-size: 10px;
        }
      }
      .TransferArea.image-present:hover {
        width: 40vw;
        height: 150px;
        border-radius: 4px;
        outline: 2px dashed #0091d7;
        background: #293741;
      }
      .DescImageSelected {
        color: #dbdbdb;
        display: flex;
      }
      .ImageSelectedArea {
        border: 1px solid #0091d7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border-radius: 4px;
        color: #dbdbdb;
        .DeleteButton {
          background-color: #212c32;
          border: none;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .ImageAreaExcell {
      // position: relative;
      margin: 10px auto;
      display: flex;
      flex-direction: column;
      // width: 80vw;
      // width: 50vw;
      width: 100%;
      left: 0;
      top: 14vh;
      @media screen and (min-width: 1200px) {
        width: 50vw;
        left: 20%;
        max-width: 700px;
      }
      .TransferArea {
        // width: 80vw;
        // margin: 0 200px;
        height: 300px;
        border-radius: 4px;
        outline: 2px dashed #0091d7;
        background: #1a2328;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        justify-content: center;
        width: 100%;
        Img {
          max-width: 200px;
          max-height: 130px;
        }
        .TransferText {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .TransferTextImage {
            color: #bebebf;
            margin-left: 5px;
            font-size: 12px;
          }
        }
        .TransferTextFormat {
          color: #676767;
          font-size: 10px;
        }
      }
      .TransferArea.image-present:hover {
        width: 40vw;
        height: 150px;
        border-radius: 4px;
        outline: 2px dashed #0091d7;
        background: #293741;
      }
      .DescImageSelected {
        color: #dbdbdb;
        display: flex;
      }
      .ImageSelectedArea {
        border: 1px solid #0091d7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border-radius: 4px;
        color: #dbdbdb;
        .DeleteButton {
          background-color: #212c32;
          border: none;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .CopyLinkArea {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: auto 50px;
      min-height: 245px;
      justify-content: center;
      .CopyLinkTopText {
        color: #dbdbdb;
        margin-bottom: 40px;
      }
      .CopyLinkBottomButton {
        display: flex;
        justify-content: space-evenly;
        .Link {
          border: 1px solid #3487be;
          border-radius: 8px;
          padding: 8px 14px;
        }
      }
    }
    .NewCompanyArea {
      display: flex;
      flex-direction: column;
      .ClientButtons {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 100px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
    }
    .AllCompaniesArea {
      position: relative;
      margin: 10px auto;
      height: 240px;
      max-height: 240px;
      overflow-y: auto;
    }
    .ButtonsArea {
      display: flex;
      justify-content: space-evenly;
      .ButtonConfirm {
        border-radius: 8px;
        background: #0091d7;
        display: flex;
        min-width: 160px;
        height: 45px;
        padding: 7px 6px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #06080a;
        border: none;
        text-transform: capitalize;
        font-weight: bold;
        cursor: pointer;
      }
      .ButtonDelete {
        border-radius: 8px;
        background: #212c32;
        display: flex;
        width: 160px;
        height: 45px;
        padding: 7px 6px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fe0000;
        border: 1px solid #fe0000;
        text-transform: capitalize;
        font-weight: bold;
        cursor: pointer;
      }
      .ButtonCancel {
        border-radius: 8px;
        background: #212c32;
        display: flex;
        width: 160px;
        height: 45px;
        padding: 7px 6px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #0091d7;
        border: 1px solid #0091d7;
        text-transform: capitalize;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .ClientPlanArea {
      display: flex;
      flex-direction: column;
      height: 200px;
      @media screen and (max-width: 768px) {
        height: 180px;
        margin: 40px 0;
      }
      .ClientPlanButtons {
        display: flex;
        color: #fff;
        justify-content: center;
        margin: 15px 10% 5px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 135px;
          margin: 5px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
    }
    .ClientBankArea {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      height: 150px;
      @media screen and (max-width: 768px) {
        height: 180px;
      }
      .ClientButtons {
        display: flex;
        color: #fff;
        justify-content: center;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 100px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
    }
    .ClientArea {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      min-height: 200px;
      @media screen and (max-width: 768px) {
        // height: 180px;
      }
      .ClientButtonsZoneOne {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 40px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtons {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 100px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtonsName {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 50px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtonsZoneThree {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 150px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
    }
    .PermissionContent {
      position: relative;
      min-height: 210px;
      .PermissionArea {
        margin: 15px;
        grid-gap: 10px;
        @media screen and (min-width: 768px) {
          display: grid;
          margin: 15px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 10px;
          grid-template-rows: 50% 50%;
          grid-template-areas:
            "header header clients clients"
            "credits resale dashboard admin";
        }
        .Column {
          border: 1px solid #3487be;
          margin: 8px;
          border-radius: 4px;
          padding: 8px 5px;
          @media screen and (min-width: 768px) {
            padding: 5px;
          }
          .Title {
            font-size: 15px;
            margin: 0 0 10px 0;
            font-weight: 600;
          }
          .PermissionItems {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
            gap: 10px;
            .Item {
              font-size: 12px;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            Label {
              cursor: pointer;
            }
          }
        }
      }
    }
    .ClientAreaAddCredit {
      display: flex;
      flex-direction: column;
      min-height: 210px;
      margin: 15px 0;
      justify-content: space-evenly;
      @media screen and (max-width: 768px) {
        margin: 40px 0;
        height: 100px;
      }
      .ClientButtonsZoneOne {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 40px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientPlanButtons {
        display: flex;
        color: #fff;
        justify-content: center;
        margin: 5px 10%;
        // height: 50px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 60px;
          margin: 5px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtons {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // height: 90px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          margin: 5px 0;
          @media screen and (min-width: 768px) {
            min-height: 5px;
            margin: 0;
          }
        }
      }
      .ClientButtonsZoneThree {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 150px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
    }
    .ClientAreaAddSupplier {
      display: flex;
      flex-direction: column;
      min-height: 215px;
      justify-content: space-evenly;
      margin-bottom: 15px;
      @media screen and (max-width: 768px) {
        // height: 180px;
      }
      .ClientButtonsZoneOne {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 35px;
          margin: 1px 10%;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtonsZoneThree {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        height: 40px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 120px;
          margin: 5px 30px 10px;
        }
        .FilterTitle {
          min-width: 118px;
          color: #1976d2;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtonsZoneDns {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 8px 30px;
        height: 40px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 180px;
          margin: 5px 30px 10px;
        }
        .FilterTitle {
          min-width: 118px;
          color: #1976d2;
        }
      }
    }
    .CopyInfoArea {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 290px;
      @media screen and (max-width: 768px) {
        // height: 180px;
      }
      .ClientButtons {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 5px 20px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 100px;
          margin: 15px 10% 15px;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
      .ClientButtonsZoneThree {
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        margin: 15px 30px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: 170px;
          margin: 15px 10% 15px;
        }
        .ButtonArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          min-height: 55px;
          @media screen and (min-width: 768px) {
            min-height: 5px;
          }
        }
      }
    }
    .IndicatedArea {
      width: 80%;
      margin: auto;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .IndicatedTop {
        border-radius: 8px;
        border: 1px solid #3487be;
        padding: 30px 15px;
      }
      .IndicatedBottom {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #3487be;
        padding: 30px 15px;
        .ClientButtons {
          display: flex;
          color: #fff;
          justify-content: center;
          align-items: center;
          margin: 5px 20px;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            height: 100px;
            margin: 15px 10% 15px;
          }
          .ButtonArea {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            min-height: 55px;
            @media screen and (min-width: 768px) {
              min-height: 5px;
            }
          }
        }
      }
    }
  }
  .DeleteButton {
    cursor: pointer;
    background-color: #212c32;
    border: none;
    display: flex;
    align-items: center;
    // width: 20px;
    padding: 0 2px;
    img {
      width: 20px;
    }
  }
}
