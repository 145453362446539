.LoginMain {
  background-color: #1a2328;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .LoginCard {
    display: flex;
    width: 25%;
    max-height: 60%;
    padding: 80px 72px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 74px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #171f23;
    @media screen and (max-width: 1200px) {
      width: 70%;
      left: 20%;
    }
    @media screen and (max-width: 768px) {
      // width: 100%;
      left: 0;
    }
    .LoginTitle {
      position: relative;
      color: #ffffff;
      font-size: 20px;
      line-height: 100%;
    }
    .LoginCompanyTitle {
      position: relative;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
    .LoginDescText {
      position: relative;
      color: #fff;
      font-size: 16px;
      text-align: center;
      font-weight: 300;
    }
    .LoginCompanyImg {
      position: relative;
      width: 120px;
    }
  }
  .ButtonGoogle {
    margin: auto;
  }
}
