.ButtonMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3487be;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  .LeftIcon {
    fill: #ff0000;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .RightIcon {
    fill: #ff0000;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .Text {
    font-weight: bold;
  }
}
