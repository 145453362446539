.TopNavDesktop {
  box-sizing: border-box;
  // position: fixed;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: 99;
  height: 60px;
  background: #212c32;
  box-shadow: 0px 10px 15px 0px #212c32;
  @media screen and (max-width: 768px) {
    display: none;
  }
  .DesktopNav {
    padding: 0 15px;
    background: #212c32;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    position: relative;
    height: 60px;
    // box-shadow: 0px 25px 15px 0px rgba(72, 89, 102, 0.1);

    .LeftMenu {
      width: 100%;
      height: 80px;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      box-sizing: border-box;
      flex-wrap: nowrap;
      justify-content: space-between;
      a {
        text-decoration: none;
      }
      .YellowButton {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 90px;
        padding: 0 5px;
        height: 46px;
        image-rendering: -webkit-optimize-contrast;
        background-color: #212c32;
        border: 1px solid #3487be;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 10px;
        color: #d6a016;
        @media screen and (min-width: 1024px) {
          min-width: 70px;
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) {
          min-width: 100px;
          font-size: 14px;
        }
        @media screen and (min-width: 1992px) {
          min-width: 120px;
          font-size: 14px;
        }
      }
      .YellowButton:hover {
        background-color: #d6a016;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: color 0.5s ease;
      }
      .PinkButton {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 90px;
        padding: 0 5px;
        height: 46px;
        image-rendering: -webkit-optimize-contrast;
        background-color: #212c32;
        border: 1px solid #3487be;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 10px;
        color: #ee82ee;
        @media screen and (min-width: 1024px) {
          min-width: 70px;
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) {
          min-width: 100px;
          font-size: 14px;
        }
        @media screen and (min-width: 1992px) {
          min-width: 120px;
          font-size: 14px;
        }
      }
      .PinkButton:hover {
        background-color: #ee82ee;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: color 0.5s ease;
      }
      .GreenButton {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 90px;
        padding: 0 5px;
        height: 46px;
        image-rendering: -webkit-optimize-contrast;
        background-color: #212c32;
        border: 1px solid #3487be;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 10px;
        color: #16d69c;
        @media screen and (min-width: 1024px) {
          min-width: 70px;
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) {
          min-width: 100px;
          font-size: 14px;
        }
        @media screen and (min-width: 1992px) {
          min-width: 120px;
          font-size: 14px;
        }
      }
      .GreenButton:hover {
        background-color: #16d69c;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: color 0.5s ease;
      }
      .PurpleButton {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 90px;
        padding: 0 5px;
        height: 46px;
        image-rendering: -webkit-optimize-contrast;
        background-color: #212c32;
        border: 1px solid #3487be;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 10px;
        color: #f765a3;
        @media screen and (min-width: 1024px) {
          min-width: 70px;
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) {
          min-width: 100px;
          font-size: 14px;
        }
        @media screen and (min-width: 1992px) {
          min-width: 120px;
          font-size: 14px;
        }
      }
      .PurpleButton:hover {
        background-color: #f765a3;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: color 0.5s ease;
      }
      .BlueButton {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 90px;
        padding: 0 5px;
        height: 46px;
        image-rendering: -webkit-optimize-contrast;
        background-color: #212c32;
        border: 1px solid #3487be;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 10px;
        color: #06ade2;
        @media screen and (min-width: 1024px) {
          min-width: 70px;
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) {
          min-width: 100px;
          font-size: 14px;
        }
        @media screen and (min-width: 1992px) {
          min-width: 120px;
          font-size: 14px;
        }
      }
      .BlueButton:hover {
        background-color: #06ade2;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: color 0.5s ease;
      }
      .CardInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 90px;
        padding: 0 5px;
        height: 46px;
        image-rendering: -webkit-optimize-contrast;
        background-color: #212c32;
        border: 1px solid #3487be;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 10px;
        @media screen and (min-width: 1024px) {
          min-width: 70px;
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) {
          min-width: 100px;
          font-size: 14px;
        }
        @media screen and (min-width: 1992px) {
          min-width: 120px;
          font-size: 14px;
        }
      }
    }
    .RightMenu {
      display: flex;
      width: 70%;
      flex: 1 1 auto;
      align-items: center;
      box-sizing: border-box;
      flex-wrap: nowrap;
      justify-content: flex-end;
      .IconsButtons {
        display: flex;
        justify-content: space-between;
        min-width: 175px;
        @media screen and (max-width: 1024px) {
          min-width: 135px;
        }
      }
      .Menu {
        min-width: 250px;
      }
      .Avatar {
        margin: 0 10px 0 0;
      }
    }
  }
}
.TopNavMobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
  .MenuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); /* Sombra que cobre toda a tela */
  }
  .MobileNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 15px 5px 15px;
    min-height: 70px;
    .RightMenu {
      display: flex;
      .IconButton {
        width: 60px;
      }
      img {
        width: 30px;
      }
      .BurguerImg {
        height: 70px;
        background-color: #212c32;
        border: none;
      }
    }
    .LeftMenu {
      img {
        height: 60px;
        margin-top: 10px;
      }
    }
  }
}
