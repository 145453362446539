.TitleMain {
  display: flex;
  align-items: flex-start;
  color: #3487be;
  //   margin: 10px 0;

  .Icon {
    fill: #ff0000;
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .Title {
    font-weight: bold;
    min-width: 162px;
  }
}
