.AdminMain {
  background-color: #1a2328;
  .AdminTop {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }
    .TopButtonsArea {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-left: 20px;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        margin-left: 1px;
        justify-content: center;
      }
      .TableActionButtons {
        background: #16d69c;
        color: #353535;
        border-radius: 42px;
      }
    }
  }
  .TableCell {
    padding: 10px 16px 10px 16px;
    color: "#fff";
    border-color: "#275370";
    margin-right: 50px;
    .TableButtonGreen {
      background: #16d69c;
      color: #353535;
      border-radius: 42px;
      text-align: center;
      min-width: 100px;
      font-size: 11px;
    }
    .TableButtonRed {
      background: #f56c89;
      color: #353535;
      border-radius: 42px;
      text-align: center;
      min-width: 100px;
    }

    .TableStatusPending {
      border-radius: 42px;
      background: #d6a016;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #353535;
      max-width: 70px;
      text-transform: capitalize;
      font-size: 12px;
      padding: 2px 8px 2px 6px;
      // font-weight: bold;
      margin: auto;
    }
    .TableStatusActive {
      border-radius: 42px;
      background: #16d69c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #353535;
      max-width: 60px;
      text-transform: capitalize;
      font-size: 12px;
      padding: 2px 8px 2px 6px;
      margin: auto;
      // font-weight: bold;
    }
  }
}
