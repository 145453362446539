.ModalDefault {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: auto;
  margin: 30px 30px;
  border: 1px solid #3487be;
  border-radius: 8px;
}
.ButtonConfirm {
  border-radius: 8px;
  background: #3487be;
  display: flex;
  min-width: 160px;
  height: 45px;
  padding: 7px 6px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #06080a;
  border: none;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
}
.ButtonConfirmActive {
  border-radius: 8px;
  background: #16d69c;
  display: flex;
  min-width: 160px;
  height: 45px;
  padding: 7px 6px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #06080a;
  border: none;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
}
