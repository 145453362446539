.AdminMain {
  background-color: #1a2328;
  .AdminTop {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }
    .TopButtonsArea {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-left: 20px;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        margin-left: 1px;
        justify-content: center;
      }
    }
  }
  .TableCell {
    padding: 10px 16px 10px 16px;
    color: "#fff";
    border-color: "#275370";
    margin-right: 50px;
    .TableActionButtons {
      display: flex;
      justify-content: space-between;
      .ActionButton {
        cursor: pointer;
        border-radius: 8px;
        background: #0091d7;
        display: flex;
        width: 70px;
        height: 30px;
        padding: 7px 6px;
        justify-content: center;
        align-items: center;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      }
      .ActionButton:disabled {
        cursor: none;
        border-radius: 8px;
        background: #7d7d7d;
        display: flex;
        width: 70px;
        height: 30px;
        padding: 7px 6px;
        justify-content: center;
        align-items: center;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        color: #06080a;
      }
      .DeleteButton {
        cursor: pointer;
        background-color: #212c32;
        border: none;
        display: flex;
        align-items: center;
        // width: 20px;
        padding: 0 2px;
        img {
          width: 20px;
        }
      }
    }

    .TableStatusPending {
      border-radius: 42px;
      background: #d6a016;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #353535;
      max-width: 70px;
      text-transform: capitalize;
      font-size: 12px;
      padding: 2px 8px 2px 6px;
      // font-weight: bold;
      margin: auto;
    }
    .TableStatusActive {
      border-radius: 42px;
      background: #16d69c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #353535;
      max-width: 60px;
      text-transform: capitalize;
      font-size: 12px;
      padding: 2px 8px 2px 6px;
      margin: auto;
      // font-weight: bold;
    }
    .TableCheck {
      width: 38px;
      height: 38px;
    }
    .TableStatusInactive {
      border-radius: 42px;
      background: #f56c89;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #60130e;
      max-width: 60px;
      text-transform: capitalize;
      font-size: 12px;
      padding: 2px 8px 2px 6px;
      margin: auto;
      // font-weight: bold;
    }
  }
  .ColumnsButtonTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #212c32;
    color: rgb(0, 145, 215);
    border: none;
    font-size: 11px;
    // min-width: 70px;
    Img {
      margin-left: 3px;
    }
  }
}
.ClientPlanArea {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    // height: 180px;
    margin: 20px 0;
    align-items: center;
  }
  .ClientPlanButtons {
    display: flex;
    color: #fff;
    justify-content: center;
    margin: 15px 10% 5px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      // height: 135px;
      margin: 5px 10%;
    }
    .ButtonArea {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // min-height: 55px;
      z-index: 9999999;
      @media screen and (min-width: 768px) {
        min-height: 5px;
      }
    }
  }
}
