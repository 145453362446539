.AsideNav {
  background-color: #212c32;
  @media screen and (max-width: 768px) {
    display: none;
  }
  .CardCompany {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: #212c32;
      border: none;
    }
    .ImgCompany {
      width: 70px;
    }
    .ImgCompany {
      width: 70px;
    }
  }
  .CustomSidebar {
    border: none;
  }
  .Menu {
    background-color: #212c32;
    color: white;
    ul {
      background-color: #212c32;
    }
    a:active,
    a:focus {
      border-radius: 16.909px;
      background: linear-gradient(180deg, #3487be 0%, #569ac7 100%);
    }
    a:hover {
      border-radius: 16.909px;
      background: linear-gradient(180deg, #3487be 0%, #569ac7 100%);
    }
    .MenuItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
      a {
        text-decoration: none;
      }
      .IconAsideNav {
        min-width: 35px;
        margin-right: 15px;
        width: 20px;
        height: 20px;
      }
      .TextAsideNav {
        min-width: 95px;
      }
    }
    .MenuItemActive {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
      a {
        border-radius: 16.909px;
        background: linear-gradient(180deg, #3487be 0%, #569ac7 100%);
        text-decoration: none;
      }
      .IconAsideNav {
        min-width: 35px;
        margin-right: 15px;
        width: 20px;
        height: 20px;
      }
      .TextAsideNav {
        min-width: 95px;
      }
      
    }
    .MenuItemLogout {
      position: fixed;
      bottom: 1px;
      // border-top: 1px solid #fff;
    }
  }
}
.BackDivOfMenu {
  background: transparent !important;
  background-color: none !important;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
div[class*="MuiDrawer-paper"] {
  /* Estilos específicos para a div com classes contendo 'MuiDrawer-paper' */
  background-color: transparent;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.AsideNavMobile {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #212c32;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    display: none;
  }
  .AsideNavButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    .IconAsideNavMob {
      width: 25px;
    }
  }
  .AsideNavButtonActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 16.909px;
    background: linear-gradient(180deg, #3487be 0%, #569ac7 100%);
    .IconAsideNavMob {
      width: 30px;
    }
  }
}
