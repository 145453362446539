.LoginMain {
  background-color: #1a2328;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .LoginCard {
    display: flex;
    width: 25%;
    max-width: 460px;
    height: 732px;
    max-height: 70%;
    padding: 60px 72px 40px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // gap: 74px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #171F23;
    @media screen and (max-width: 1200px) {
      width: 70%;
      left: 20%;
    }
    @media screen and (max-width: 768px) {
      // width: 100%;
      left: 0;
    }
    .LoginTitle{
      position: relative;
      color: #FFFFFF;
      font-size: 20px;
      line-height: 100%; 
    }
    .LoginCompanyTitle{
      position: relative;
      color: #FFF;
      font-size: 24px;
      font-weight: 600;
    }
    .LoginDescText{
      position: relative;
      color: #FFF;
      font-size: 16px;
      text-align: center;
      font-weight: 300;
    }
    .LoginWarningText{
      position: relative;
      color: #FFF;
      font-size: 12px;
      text-align: center;
      font-weight: 300;
    }
    .LoginCompanyImg{
      position: relative;
      height: 150px;
    }
    .ButtonGoogle {
      margin: auto;
    }
  }
}
