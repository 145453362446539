.SpinnerMain {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  .SpinnerCompanyImg{
    width: 100px;
  }
  .SpinnerText{
    color: #4a9acf;
    margin-left: 15px;
  }
}
