.SpinnerMain {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50vh;
  position: relative;
  justify-content: center;
  flex-direction: column;
  .SpinnerCompanyImg{
    width: 300px;
    filter: grayscale(100%) sepia(100%) hue-rotate(180deg);
    opacity: 0.6; /* Define a opacidade como 20% */
  }
  .SpinnerText{
    color: #4a9acf;
    filter: grayscale(100%) sepia(100%) hue-rotate(180deg);
    opacity: 0.6; /* Define a opacidade como 20% */
  }
}
