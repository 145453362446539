.CardContent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;
  background: #212c32;
  margin: 10px 0;
  .CardTop {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #585858;
    padding: 20px 25px;
    font-weight: bold;
    .CardLeftTitle {
      color: #828282;
      font-size: 16px;
    }
    .CardLeftSubTitle {
      color: #3487be;
      font-size: 24px;
    }
  }
  .CardBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
tspan {
  fill: #3487be;
}
