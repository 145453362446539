body {
  margin: 0px;
  background-color: #1a2328;
  * {
    scrollbar-color: #0091d7 #275370;
  }
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #0091d7;
  }

  *::-webkit-scrollbar-track {
    background-color: #275370;
  }
  .Layout {
    background-size: cover;
    // background-image: url(../../assets/background.png);
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: #fff;
    display: flex;
    padding-bottom: 0px;
    min-height: calc(100vh - 60px);
    width: 100%;
    @media (min-width: 992px) {
      padding-bottom: 0px;
    }
    .LayoutRight {
      background-size: cover;
      // background-image: url(../../assets/background.png);
      background-attachment: fixed;
      background-position: center top;
      background-repeat: no-repeat;
      background-color: #212c32;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      min-height: calc(100vh - 60px);
      width: 100%;
      @media (min-width: 992px) {
        padding-bottom: 0px;
      }
      .MainLogged {
        background-color: #1a2328;
        height: 155vh;
        padding: 35px 0 0 35px;
        @media (min-width: 768px) {
          height: 100%;
        }
      }
      .Main {
        background-color: #1a2328;
        height: 100%;
        @media (min-width: 1200px) {
          // margin-top: 100px;
        }
      }
    }
    // .Aside{
    //   background-color: #212C32;
    // }
    .Aside + .LayoutRight {
      margin-left: 0;
      margin-right: 150;
      // width: 100vw;
      @media (min-width: 992px) {
        margin-left: 250px;
      }
    }

    min-height: calc(100vh);
  }
}
