.DashboardMain {
  background-color: #1a2328;
  .DashboardContent{
    display: flex;
    flex-direction: column;
    .CardArea{
      display: flex;
      justify-content: space-between;
      .CardLeft{
        display: flex;
      }
      .CardRight{
        display: flex;
      }
    }
  }
  .AdminTop{
    display: flex;
    justify-content: space-between;
  }
}
